import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tooltip/dist/react-tooltip.css";

import ScrollToTop from "./shared/components/scrollToTop/ScrollToTop";
import { ReactComponent as ErrorIcon } from "assets/images/toast-error.svg";
import { ReactComponent as SuccessIcon } from "assets/images/toast-success.svg";
import UpsideRoutes from "./routes/UpsideRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const App = () => {
  const pathname = window.location.pathname;

  const isAppPath = pathname.startsWith("/app");

  const isEntityPath =
    pathname.includes("/buyers") ||
    pathname.includes("/agents") ||
    pathname.includes("/admin") ||
    pathname.includes("/sellers");

  const isAuthPath =
    pathname.includes("/login") || pathname.includes("/signup");



  if (!isAppPath && (isEntityPath && isAuthPath)) {
    window.location.replace("/app/agents/login");
    return null;
  }





  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/app">
        <ScrollToTop />
        <Suspense fallback={<p>Loading upside...</p>}>
          <UpsideRoutes />
        </Suspense>
      </BrowserRouter>

      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        icon={({ type }) => {
          switch (type) {
            case 'error':
              return <ErrorIcon />;
            case 'success':
              return <SuccessIcon />;
            default:
              return null;
          }
        }} />
    </QueryClientProvider>
  );
};

export default App;
