import { array, number, object, string } from "yup";

const uniqueCities = (addresses) => {
  const cities = addresses.map((address) => address.city);
  const uniqueCities = new Set(cities);
  return uniqueCities.size === cities.length;
};

export const propertyAlertSchema = object({
  addresses: array()
    .of(
      object().shape({
        city: string().required("city is required"),
      })
    )
    .min(1, "Need at least an address")
    .test("unique-cities", "Cities must be unique", function (value) {
      return uniqueCities(value);
    }),
  min_year_built: number().moreThan(1900, "Year built must be after 1900"),
  max_year_built: number().moreThan(1900, "Year built must be after 1900"),
});
