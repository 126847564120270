import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";

import { ReactComponent as BackButtonIcon } from "../../../../src/assets/images/agent-icons/left-arrow.svg";

import { useOnBoarding } from "../../provider/OnBoardingProvider";
import { AGENT_TYPE_SINGLE_AGENT } from "../../helpers/constants";

const AgentSignupProgressBar = () => {
  const { formik, progressLevel, currentStep, onBackButtonClick } =
    useOnBoarding();

  let progress;

  if (progressLevel === 1) {
    progress = 20;
  } else if (progressLevel === 2) {
    progress = 40;
  } else if (
    progressLevel === 3 ||
    (formik?.values.type === AGENT_TYPE_SINGLE_AGENT && progressLevel === 4)
  ) {
    progress = 60;
  } else if (progressLevel === 4) {
    progress = 80;
  } else {
    progress = 100;
  }

  const stepNumber =
    formik?.values?.type === AGENT_TYPE_SINGLE_AGENT && currentStep === 5
      ? "4"
      : progressLevel;

  const totalStepNumber =
    formik?.values.type === AGENT_TYPE_SINGLE_AGENT ? "04" : "05";

  return (
    <div className="sticky top-0 bg-white pt-9 z-10">
      <div className="flex justify-between items-center">
        {currentStep !== 1 && (
          <button onClick={onBackButtonClick}>
            <BackButtonIcon />
          </button>
        )}
        <div className="flex-grow text-center">
          <h4 className="text-xs text-[#394E77] font-inter-medium">
            Step 0{stepNumber} of {totalStepNumber} Complete
          </h4>
        </div>
        <div className="bg-darkturquoise text-xs px-2 py-1 text-white rounded-lg">
          0{stepNumber}/{totalStepNumber}
        </div>
      </div>
      <ProgressBar
        className="mt-4"
        width="100%"
        height={"6px"}
        bgColor="#1AC8DB"
        isLabelVisible={false}
        completed={progress}
      />
    </div>
  );
};

export default AgentSignupProgressBar;
