import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useRedirect } from "../../../store/redirect";
import { loginSchema } from "./validation";
import { setToken } from "../../../shared/helpers/util";
import logo from "../../../assets/images/logotxt.svg";
import buyerBanner from "../../../assets/images/investerlogin.png";
import sellerBanner from "../../../assets/images/sellerLogin.png";
import adminBanner from "../../../assets/images/admin_login_image.png";
import adminOverlay from "../../../assets/images/admin_overlay.png";
import LoginBannerComponent from "./LoginBannerComponent";
import CommonLogin from "./CommonLogin";

const userTypeConfig = {
  admin: {
    userType: "admin",
    logo: null,
    subHeading: "Log in to Upside Admin Dashboard ",
    backgroundClass: "auth_aside--bg",
    loginTitle: "Admin Login",
    backgroundImage: adminBanner,
    overlay: adminOverlay,
  },
  agents: {
    userType: "agents",
    logo: null,
    heading: "Turn your database into a databank.",
    subHeading: "Login to Upside Agent Dashboard",
    backgroundClass: "auth_aside--bg",
    loginTitle: "Agent Login",
  },
  sellers: {
    userType: "sellers",
    logo: sellerBanner,
    heading: "Enhance Your Property Listings",
    subHeading: "Log in to your Seller Dashboard",
    backgroundClass: "",
    loginTitle: "Seller Login",
  },
  buyers: {
    userType: "buyers",
    logo: buyerBanner,
    heading: "Access Your Investment Opportunities",
    subHeading: "Log in to your Investor Dashboard",
    backgroundClass: "",
    loginTitle: "Investor Login",
  },
};

const Login = ({ onSubmit, forgotPasswordUrl, title }) => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const redirect = useRedirect((state) => state.redirect);
  const resetRedirect = useRedirect((state) => state.resetRedirect);
  const [params] = useSearchParams();

  const urlParts = window.location.href.split("/").filter((part) => part);
  const userType = urlParts.slice(-2)[0] || "agents";

  const {
    mutate: userLogin,
    isLoading,
    error,
    isError,
  } = useMutation((contact) => onSubmit(contact), {
    onSuccess: (res) => {
      if (res?.data?.domain) {
        const domain =
          process.env.REACT_APP_DEVELOPMENT_ENVIRONMENT === "local"
            ? "http://localhost:3000"
            : `http://${res?.data?.domain}`;

        const redirectURL = `${domain}/app?token=${res?.data?.token}`;
        window.location.replace(redirectURL);
        resetRedirect();
      }

      setToken("Token", res?.data?.token);
      navigate(redirect?.isRedirect ? redirect.url : params.has("onBoarding") ? "/?onBoarding" : "/");
      resetRedirect();
    },
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginSchema,
    validateOnChange: false,
    onSubmit: (values) => userLogin(values),
    title,
  });

  const config = userTypeConfig[userType] || userTypeConfig.agents;

  return (
    <div className="h-screen flex items-center justify-center p-5">
      <div
        className={clsx(
          "flex max-w-[68.625rem] maxLg:max-w-[calc(100vh-50px)] mx-auto max-h-[40.5rem] maxLg:max-h-full overflow-hidden bg-white relative z-0 w-full shadow-agentsAuthCards rounded-[1.25rem] h-full",
          config.userType === "admin" && "relative"
        )}
      >
        {config.userType === "admin" && (
          <img
            src={logo}
            alt="logo"
            className="lg:h-10 h-8 mx-auto mb-5 absolute left-11 top-11 maxLg:hidden"
          />
        )}

        {config.userType !== "admin" && (
          <div
            className={clsx(
              "h-full maxLg:absolute maxLg:left-0 maxLg:right-0 maxLg:top-0 w-1/2 maxLg:w-full",
              config.backgroundClass
            )}
          >
            <LoginBannerComponent config={config} />
          </div>
        )}

        {config.userType !== "admin" ? (
          <CommonLogin
            userType={userType}
            isError={isError}
            config={config}
            formik={formik}
            passwordShown={passwordShown}
            setPasswordShown={setPasswordShown}
            isLoading={isLoading}
            forgotPasswordUrl={forgotPasswordUrl}
            navigate={navigate}
            error={error}
            userTypeConfig={userTypeConfig}
          />
        ) : (
          <div className="w-full flex justify-center items-center z-20"
            style={{
              backgroundImage: config.userType === "admin" ? `url(${config.backgroundImage})` : "none",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <CommonLogin
              userType={userType}
              isError={isError}
              config={config}
              formik={formik}
              passwordShown={passwordShown}
              setPasswordShown={setPasswordShown}
              isLoading={isLoading}
              forgotPasswordUrl={forgotPasswordUrl}
              navigate={navigate}
              error={error}
              userTypeConfig={userTypeConfig}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
